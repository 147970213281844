import { clsx } from 'clsx';
import NextLink, { type LinkProps } from 'next/link';

import { Tooltip } from '@squadnet/frontend-web-ui';

interface MenuItemProps extends Partial<LinkProps> {
  children: React.ReactNode;
  tip: React.ReactNode;
  selected?: boolean;
  className?: string;
}
export const MenuItem = ({ children, selected, tip, className, ...props }: MenuItemProps) => {
  const Component = props.href ? NextLink : ('div' as React.ElementType);
  return (
    <Tooltip
      placement='right'
      tooltipInnerClassName='!min-w-max'
      renderTrigger={ref => (
        <Component className='relative h-fit w-full px-3' {...props} ref={ref}>
          {selected ? <div className='absolute inset-y-3 left-0 w-1 rounded-md bg-mono' /> : null}
          <div
            className={clsx(
              'inline-flex h-[48px] w-full items-center justify-center p-[3px] transition-all hover:rounded-md',
              selected ? 'rounded-md bg-primary' : 'rounded-full bg-background',
              className,
            )}
          >
            {children}
          </div>
        </Component>
      )}
    >
      {tip}
    </Tooltip>
  );
};
