import { useAtomValue } from 'jotai';

import { authenticatedAtom, userAuthenticationAtom } from '@squadnet/frontend-shared-states';

const useAuthenticationGuard = () => {
  const isAuthenticated = useAtomValue(authenticatedAtom);
  const { userInfo, authLoaded } = useAtomValue(userAuthenticationAtom);
  return { isAuthenticated, userInfo, authLoaded };
};

export default useAuthenticationGuard;
