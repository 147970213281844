import { ApolloProvider } from '@apollo/client';

import { client } from '@/services/graphql/client';

interface ProviderProps {
  children?: React.ReactNode;
}
export const Apollo = ({ children }: ProviderProps) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
