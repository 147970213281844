import { useAtomValue, useSetAtom } from 'jotai';

import { userAuthorizationAtom, userProfileAtom, userThemeAtom } from '@/state/userAuthorization';

const useAuthorization = () => {
  const refetch = useSetAtom(userAuthorizationAtom);
  const theme = useAtomValue(userThemeAtom);
  const { profile, loading } = useAtomValue(userProfileAtom);
  return {
    loading,
    profile,
    refetch,
    theme,
  };
};

export default useAuthorization;
