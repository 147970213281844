'use client';

import dynamic from 'next/dynamic';
import { ReactNode, useEffect } from 'react';

import { type Inline } from '@squadnet/frontend-shared-states';
import { Spinner } from '@squadnet/frontend-web-ui';

import useAuthenticationGuard from '@/hooks/guards/useAuthenticationGuard';
import { useAuthenticationAction } from '@/hooks/logic/useAuthenticationAction';
import useAuthorization from '@/hooks/useAuthorization';

const SignIn = dynamic(() => import('./components/SignIn'));
const SignUp = dynamic(() => import('./components/SignUp'));
const VerifyToken = dynamic(() => import('./components/VerifyToken'));

interface InlineLoginProps {
  signInTitle?: string;
  signUpTitle?: string;
  defaultScreen?: Inline;
  children?: ReactNode;
  className?: string;
  onAuthed?: () => void;
}

export const InlineLogin = (
  { signInTitle, signUpTitle, defaultScreen, className, onAuthed, children }: InlineLoginProps,
) => {
  const { isAuthenticated } = useAuthenticationGuard();
  const { profile } = useAuthorization();
  const { inlineLogin, mergingGuest, setInlineLogin, authLoaded } = useAuthenticationAction({});
  useEffect(() => {
    if (defaultScreen) {
      setInlineLogin(defaultScreen);
    }
  }, [defaultScreen]);

  useEffect(() => {
    if (!mergingGuest && isAuthenticated) {
      onAuthed?.();
    }
  }, [isAuthenticated, mergingGuest]);

  if (!authLoaded) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <div className={className}>
        {inlineLogin === 'SIGN_IN' && <SignIn title={signInTitle} />}
        {inlineLogin === 'SIGN_UP' && <SignUp title={signUpTitle} />}
        {inlineLogin === 'VERIFY_TOKEN' && <VerifyToken />}
      </div>
    );
  }

  if (mergingGuest) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (!profile?.id) {
    return null;
  }

  return <>{children}</>;
};
