import { useAtomValue } from 'jotai';
import { ReactNode, useEffect } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { getTextColor, hexToRgb } from '@squadnet/frontend-shared-theme';

import { customThemeAtom } from '@/state/theme';

export const Theme = ({ children }: { children?: ReactNode }) => {
  const theme = useAtomValue(customThemeAtom);

  useEffect(() => {
    const root = document.querySelector(':root') as HTMLElement;
    if (root) {
      root.style.setProperty('--colors-primary', hexToRgb(theme.colorPrimary));
      root.style.setProperty('--colors-primary-light', hexToRgb(theme.colorPrimaryLight));
      root.style.setProperty('--colors-primary-dark', hexToRgb(theme.colorPrimaryDark));
      root.style.setProperty('--colors-secondary', hexToRgb(theme.colorSecondary));
      root.style.setProperty('--colors-secondary-light', hexToRgb(theme.colorSecondaryLight));
      root.style.setProperty('--colors-secondary-dark', hexToRgb(theme.colorSecondaryDark));
      root.style.setProperty('--colors-primary-text', hexToRgb(getTextColor(theme.colorPrimary)));
      root.style.setProperty('--colors-secondary-text', hexToRgb(getTextColor(theme.colorSecondary)));
    }
  }, [theme?.colorPrimary, theme?.colorSecondary]);

  return <SkeletonTheme>{children}</SkeletonTheme>;
};
