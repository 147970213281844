'use client';

import { clsx } from 'clsx';
import { useEffect, useMemo } from 'react';

import {
  useListAdminClubsWithNewNotifications,
  useListClubsQuery,
  useListClubsWithNewUpdate,
} from '@squadnet/frontend-client-gql-hooks';
import { useSessionStorage } from '@squadnet/frontend-shared-context';
import { Avatar, getLogoUrl, IconAdd, IconCompass, IconHome } from '@squadnet/frontend-web-ui';

import { useClub } from '@/hooks/logic/useClub';
import { useAppMenu } from '@/hooks/ui/useAppMenu';
import useAuthorization from '@/hooks/useAuthorization';
import { useMatch } from '@/hooks/useNext';

import { MenuItem } from './components/MenuItem';

export const Navigation = () => {
  const { open } = useAppMenu();
  const matched = useMatch<{ root: string; routes: string[] }>('/:root/:routes*');
  const rootRoute = matched.params?.root;
  const { profile } = useAuthorization();
  const { clubs: adminClubs, refetch: adminRefetch } = useListAdminClubsWithNewNotifications();
  const { club } = useClub();
  const [clubIds, setClubIds] = useSessionStorage<string[]>('@squadnet-club-visited', []);
  const { data } = useListClubsQuery({ variables: { ids: clubIds || [] } });
  const { clubs: memberClubs, refetch: memberRefetch } = useListClubsWithNewUpdate();

  const clubId = useMemo(() => {
    if (matched.params?.root === 'club') {
      return matched.params?.routes?.[0];
    }
    return undefined;
  }, [matched]);

  useEffect(() => {
    if (clubId && !(clubIds || []).includes(clubId)) {
      setClubIds([...(clubIds || []), clubId]);
    }
  }, [clubId]);

  useEffect(() => {
    if (profile?.id) {
      adminRefetch();
      memberRefetch();
    }
  }, [profile?.id]);

  const notJoined = useMemo(() => {
    return (
      club && adminClubs.findIndex(c => c.id === club.id) === -1 && memberClubs.findIndex(c => c.id === club.id) === -1
    );
  }, [adminClubs, club]);

  const viewedClubs = useMemo(() => {
    return (data?.listClubs?.list || []).filter(c => {
      if (club?.id === c.id) return false;
      if (adminClubs.findIndex(cl => cl.id === c.id) > -1) return false;
      if (memberClubs.findIndex(cl => cl.id === c.id) > -1) return false;
      return true;
    });
  }, [club, data, adminClubs]);

  return (
    <nav
      className={clsx(
        'fixed left-0 top-0 z-10 h-[100vh] w-[72px] flex-col justify-between bg-mono-2xlight py-3 lg:sticky lg:flex',
        open ? 'z-[9999] flex' : 'hidden',
      )}
    >
      <div className='relative w-full flex-1'>
        <div className='grid max-h-[calc(100vh-78px)] gap-3 overflow-y-auto overflow-x-hidden scrollbar-thin'>
          <MenuItem href='/discover' selected={rootRoute === 'discover'} tip={'Discover'}>
            <IconCompass />
          </MenuItem>
          <MenuItem href='/user' selected={rootRoute === 'user'} tip={'My Home'}>
            <IconHome />
          </MenuItem>
          {notJoined ? (
            <MenuItem selected={clubId === club.slug} tip={club.name || ''}>
              <Avatar src={getLogoUrl(club.logo)} name={club.name || ''} />
            </MenuItem>
          ) : null}
          {viewedClubs.map(club => (
            <MenuItem href={`/club/${club.slug}`} selected={clubId === club.slug} key={club.id} tip={club.name || ''}>
              <Avatar src={getLogoUrl(club.logo)} name={club.name || ''} />
            </MenuItem>
          ))}
          <div className='mx-2 border-t-2 border-t-mono-xlight' />
          {adminClubs.map(club => (
            <MenuItem href={`/club/${club.slug}`} selected={clubId === club.slug} key={club.id} tip={club.name || ''}>
              <Avatar src={getLogoUrl(club.logo)} name={club.name || ''} />
            </MenuItem>
          ))}
          {memberClubs.map(club => (
            <MenuItem href={`/club/${club.slug}`} selected={clubId === club.slug} key={club.id} tip={club.name || ''}>
              <Avatar src={getLogoUrl(club.logo)} name={club.name || ''} />
            </MenuItem>
          ))}
        </div>
      </div>
      <div className='absolute bottom-0 grid w-full gap-3 bg-mono-2xlight py-3'>
        <MenuItem
          href='/club/create'
          selected={rootRoute === 'club' && matched.params?.routes?.[0] === 'create'}
          tip='Create New Club'
          className='bg-gradient-to-r from-primary to-secondary'
        >
          <IconAdd variant={'white'} />
        </MenuItem>
      </div>
    </nav>
  );
};
