import { useAtom } from 'jotai';

import { appMenuState } from '@/state/appMenu';

export const useAppMenu = () => {
  const [open, setOpen] = useAtom(appMenuState);
  return {
    open,
    setOpen,
    toggle: () => setOpen(!open),
    close: () => setOpen(false),
  };
};
