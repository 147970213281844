'use client';

import { PostHogProvider } from 'posthog-js/react';

import posthogClient from '@/modules/posthog';

import { Apollo } from './Apollo';
import { Theme } from './Theme';

interface ProviderProps {
  children?: React.ReactNode;
}
export const Providers = ({ children }: ProviderProps) => {
  return (
    <Apollo>
      <PostHogProvider client={posthogClient}>
        <Theme>{children}</Theme>
      </PostHogProvider>
    </Apollo>
  );
};
