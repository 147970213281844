import { useAtom } from 'jotai';

import { appLoginState } from '@/state/appLogin';

const useAppLogin = () => {
  const [login, setLogin] = useAtom(appLoginState);
  return {
    login,
    setLogin,
    openInlineLogin: () => setLogin('SIGN_IN'),
    openInlineSignUp: () => setLogin('SIGN_UP'),
    closeInlineLogin: () => setLogin(''),
  };
};

export default useAppLogin;
