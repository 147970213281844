'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import { Dialog } from '@squadnet/frontend-web-ui';

import useAuthentication from '@/hooks/guards/useAuthenticationGuard';
import useAppLogin from '@/hooks/ui/useAppLogin';

import { InlineLogin } from '../InlineLogin';

export const LoginPrompt = () => {
  const { login, closeInlineLogin } = useAppLogin();
  const { isAuthenticated } = useAuthentication();
  const search = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const isOpen = useMemo(() => {
    return !!login || search?.get('login') === 'true';
  }, [login, search]);

  const onClose = () => {
    if (search?.get('login')) {
      router.replace(pathname || '/');
    }
    closeInlineLogin();
  };

  if (isAuthenticated) return null;

  return (
    <Dialog open={!!isOpen} onClose={onClose}>
      <div className='p-5'>{isOpen ? <InlineLogin defaultScreen={(login || 'SIGN_IN') as any} /> : null}</div>
    </Dialog>
  );
};
