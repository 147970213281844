import { colord } from 'colord';
import { atom } from 'jotai';

import { theme } from '@squadnet/frontend-web-theme';

import { clubAtom } from '@/state/currentClub';

import logger from '../modules/logger';

interface Branding {
  colorPrimary?: string | null;
  colorSecondary?: string | null;
  isEnabled?: boolean | null;
}

const parseTheme = (branding: Branding | null | undefined) => {
  const { isEnabled, colorPrimary, colorSecondary } = branding || {};
  if (isEnabled) {
    const custom: any = {};
    try {
      if (colorPrimary) {
        custom.colorPrimary = colorPrimary;
        custom.colorPrimaryDark = colord(colorPrimary).darken(0.3).toRgbString();
        custom.colorPrimaryLight = colord(colorPrimary).lighten(0.3).toRgbString();
      }
      if (colorSecondary) {
        custom.colorSecondary = colorSecondary;
        custom.colorSecondaryDark = colord(colorSecondary).darken(0.3).toRgbString();
        custom.colorSecondayLight = colord(colorSecondary).lighten(0.3).toRgbString();
      }
      return custom;
    } catch (err) {
      if (err instanceof Error) {
        logger.error(err);
        return {};
      }
    }
  } else {
    return {};
  }
};

export const customThemeAtom = atom<typeof theme>(get => {
  const { club } = get(clubAtom);
  if (club?.id) {
    return {
      ...theme,
      ...parseTheme(club?.branding),
    };
  }
  return {
    ...theme,
  };
});
