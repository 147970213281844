import { Auth } from '@aws-amplify/auth';

import { withUseAuthenticationAction } from '@squadnet/frontend-shared-states';
import { getRandomString } from '@squadnet/universal-shared-utils';

import createClient from '@/config/appsync';
import { useAnalytics, useAnalyticsUser } from '@/hooks/analytics/useAnalytics';
import { userAuthorizationAtom } from '@/state/userAuthorization';

export const useAuthenticationAction = (() => {
  const { client, reset } = createClient();
  return withUseAuthenticationAction({
    authClient: Auth,
    client,
    getRandomString,
    authorizationAtom: userAuthorizationAtom,
    analyticsHook: useAnalytics,
    analyticsUsersHook: useAnalyticsUser,
    reset,
    redirect: () => {},
  });
})();
